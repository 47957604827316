import { Pipe, PipeTransform } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { Prescription } from "../prescription.model";

@Pipe({
  name: "unique",
  standalone: true,
})
export class UniquePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(prescriptions: Prescription[]): Prescription[] {
    const uniquePrescriptions: Prescription[] = [];

    prescriptions.filter((prescription, index, array) => {
      if (
        array
          .map((prescr) => prescr.name.trim())
          .indexOf(prescription.name.trim()) === index ||
        array
          .map((prescr) => this.translate.instant(prescr.type.trim()))
          .indexOf(this.translate.instant(prescription.type.trim())) === index
      ) {
        return uniquePrescriptions.push(prescription);
      }
    });
    return uniquePrescriptions;
  }
}
